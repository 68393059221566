<template>
    <b-overlay
        :show="is_loading"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
        <b-card v-if='vacancy' no-body>
            <b-card-header>
                <b-card-title>
                  Редактировать {{ vacancy.header }}
                </b-card-title>
            </b-card-header>
            
            <b-card-body>
               <validation-observer ref="form">
          <b-form>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Заголовок вакансии</span
                    >
                    <b-form-input id="name" v-model="form.header" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >График работы</span
                    >
                    <v-select
                      :options="employments"
                      v-model="form.employments"
                      label="title"
                      :clearable="false"
                      placeholder="Выбрать график"
                      multiple
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Оплата от</span
                    >
                    <b-form-input id="pay_from" v-model="form.pay_from" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Оплата до</span
                    >
                    <b-form-input id="pay_to" v-model="form.pay_to" />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

              <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Ссылка на hh.ru</span
                    >
                    <b-form-input id="hh_url" v-model="form.hh_url" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
               
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <div class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Описание вакансии</span
                    >
                    <quill-editor
                      id="content"
                      v-model="form.content"
                      class="form-control font-weight-normal p-0"
                      style="height: auto;"
                      :options="snowOption"
                    />
                  </validation-provider>
                </div>
              </b-col>

              <b-col cols="12" class="mb-2">
                <div class="border rounded p-2">
                  <h4 class="mb-1">
                    Обложка вакансии
                  </h4>
                  <b-media
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                  >
                    <b-media-aside v-if="placeholder">
                      <b-img
                        ref="refPreviewEl"
                        :src="placeholder ? placeholder.thumb || placeholder.urls.thumb : null"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </b-media-aside>
                    <b-media-body>
                      <b-card-text class="my-50">
                        <b-link id="blog-image-text">
                          {{ placeholder ? placeholder.fileName : "" }}
                        </b-link>
                      </b-card-text>
                      <div class="d-inline-block">
                        <b-button
                          @click="preferMediaPickerAction"
                          variant="primary"
                        >
                          Выбрать файл
                        </b-button>
                      </div>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end">
              <!-- <b-button variant="gray" class="mr-1">Предпросмотр</b-button> -->
              <b-button @click="submit" variant="primary"
                >Редактировать</b-button
              >
            </div>
          </b-form>
        </validation-observer>

                   
            </b-card-body>
            
        </b-card>
    </b-overlay>
</template>

<script>

    import User from "@/modules/user/"
    import vSelect from "vue-select";

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    import { required } from '@validations'
    import { pickFile } from 'js-pick-file';
    import $utils from "@/modules/utils/"

    import "quill/dist/quill.bubble.css";

    import { quillEditor } from "vue-quill-editor";
    
    export default {

        data() {
            return {
                
                required,
                
                is_loading: false,
                
                form: {
                    
                    
                    id: -1,
                    
                    header: null,
                    employments: [],
                    content: null,
                    pay_from: null,
                    pay_to: null,
                    hh_url: null
                                        
                },
                
                placeholder: null,
                snowOption: {
                    theme: 'bubble',
                    placeholder: "",
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], 
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'font': [] }],
                            [{ 'align': [] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [ 'align', 'list', 'blockquote', 'code-block'] , 
                            ['link', 'image']
                        ]
                    }
                },
                 
                
            }
        },
        
        props: {
            vacancy: Object,
            employments: Array,
        },
        
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'vacancies' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'vacancies'
                    }
                });
            }
            
            if( perms.edit === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'vacancies'
                    }
                });
            }
            
            next( true );
            
        },
        
        methods: {  
            
            
            getMediaPlaceholderStyle( media ) {

                return {
                    position: "relative",
                    display: 'flex',
                    alignItems: "center",
                    width: "125px",
                    minWidth: "125px",
                    height: "125px",
                    backgroundImage: `url(${placeholder.thumb || placeholder.urls.thumb})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "12px",
                    margin: "0 8px",
                    padding: "0 8px"
                }
                
            },
            
            prefereMediaDeleteAction(args, index ) {
                
                this.placeholder = null;
                args.stopPropagation()
                
            },
            
            preferMediaPickerAction( args ) {
                
                let options = { 
                    accept: '.jpg, .jpeg, .png', 
                    multiple: true 
                }
                
                pickFile(options).then(files => {
                     files.forEach(file => {
                         
                        $utils.preferBeforeUploadAction( file ).then( data => {
                            
                            let object = {
                                id: -1,
                                type: data.type,
                                file: file,
                                fileName: file.name,
                                size: file.size,
                                extension: file.name.split('.').pop(),
                                duration: data.duration,
                                thumb: data.thumb,
                                placeholder: data.placeholder,
                                width: data.width,
                                height: data.height,
                                progress: {
                                    loaded: 0,
                                    total: 0
                                }
                                
                            }
                            
                            this.placeholder = object;
                            
                            this.$request.transfer("media.upload", {
                                type: data.type,
                                width: data.width,
                                height: data.height
                            }, file, ( progress ) => {
                                object.progress.total = progress.total;
                                object.progress.loaded = progress.loaded;
                            }).then( rsp => {
                                this.$set(object, "id", rsp.upload_id )
//                                object.id = rsp.upload_id;
                            });
                            
                        }); 
                         
                         
                     });
                });
                
            },
            
           submit( args ) {
                               
                if(!this.placeholder || this.placeholder.id < 0 ) return;    
                    
                this.$refs.form.validate().then(success => {
                    if( success ) {
                        this.$request.post("vacancies/update", {
                            id: this.form.id,
                            header: this.form.header,
                            employments: this.form.employments.map(item => item.id ),
                            media: this.placeholder.id,
                            content: this.form.content,
                            pay_from: this.form.pay_from,
                            pay_to: this.form.pay_to,
                            hh_url: this.form.hh_url
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: `Вакансия отредактирована`,
                                  icon: 'CheckCircleIcon',
                                  variant: 'success',
                                }
                            });
                            
                            this.$router.push({
                                name: 'admin-vacancies-list'
                            });
                            
                        });
                    }
                });
                
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect,
            quillEditor
        },
        
        computed: {
            canSendForm() {
                let count = this.medias.filter(item => item.id < 0 );
                if( count > 0 ) return false;
                return true;
            },
        },
        
        watch: {

        },
        mounted() {
            
            if( !this.vacancy ) {
                return this.$router.replace({
                    name: "admin-vacancies-list"
                });
            }

            Object.keys( this.form ).forEach(key => {
                if( this.vacancy.hasOwnProperty(key) ) {
                    this.form[key] = this.vacancy[key];
                }
            });
            
            this.placeholder = this.vacancy.media;

        }

    }

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.form-control .ql-editor {
  padding: 0;
}

.ql-tooltip {
  z-index: 9999;
}

[dir] .form-group-with-label .vs__dropdown-toggle .vs__selected {
  background-color: transparent !important;
  padding-right: 5px !important;
}
</style>