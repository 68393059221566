var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.is_loading,"variant":"transparent","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[(_vm.vacancy)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Редактировать "+_vm._s(_vm.vacancy.header)+" ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок вакансии")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.header),callback:function ($$v) {_vm.$set(_vm.form, "header", $$v)},expression:"form.header"}})]}}],null,false,1535714110)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("График работы")]),_c('v-select',{attrs:{"options":_vm.employments,"label":"title","clearable":false,"placeholder":"Выбрать график","multiple":""},model:{value:(_vm.form.employments),callback:function ($$v) {_vm.$set(_vm.form, "employments", $$v)},expression:"form.employments"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2)]}}],null,false,350968127)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("Оплата от")]),_c('b-form-input',{attrs:{"id":"pay_from"},model:{value:(_vm.form.pay_from),callback:function ($$v) {_vm.$set(_vm.form, "pay_from", $$v)},expression:"form.pay_from"}})]}}],null,false,2663326447)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("Оплата до")]),_c('b-form-input',{attrs:{"id":"pay_to"},model:{value:(_vm.form.pay_to),callback:function ($$v) {_vm.$set(_vm.form, "pay_to", $$v)},expression:"form.pay_to"}})]}}],null,false,2953249689)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("Ссылка на hh.ru")]),_c('b-form-input',{attrs:{"id":"hh_url"},model:{value:(_vm.form.hh_url),callback:function ($$v) {_vm.$set(_vm.form, "hh_url", $$v)},expression:"form.hh_url"}})]}}],null,false,1491028309)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{class:{
                    'text-muted': errors.length > 0 ? false : true,
                    'font-weight-bolder': errors.length > 0 ? true : false,
                    'text-danger': errors.length > 0 ? true : false,
                  },staticStyle:{"font-size":"12px"}},[_vm._v("Описание вакансии")]),_c('quill-editor',{staticClass:"form-control font-weight-normal p-0",staticStyle:{"height":"auto"},attrs:{"id":"content","options":_vm.snowOption},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}],null,false,2286485472)})],1)]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Обложка вакансии ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[(_vm.placeholder)?_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.placeholder ? _vm.placeholder.thumb || _vm.placeholder.urls.thumb : null,"height":"110","width":"170"}})],1):_vm._e(),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"},[_c('b-link',{attrs:{"id":"blog-image-text"}},[_vm._v(" "+_vm._s(_vm.placeholder ? _vm.placeholder.fileName : "")+" ")])],1),_c('div',{staticClass:"d-inline-block"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.preferMediaPickerAction}},[_vm._v(" Выбрать файл ")])],1)],1)],1)],1)])],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Редактировать")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }